<template>
  <div></div>
</template>

<script>
import Cookies from 'js-cookie'
import wx from 'weixin-js-sdk';
import {GetAllInPayUserInfo,CheckCode,SignContract}  from '../../api/common'
import {allinpayUser} from '../../store/allinpay'
export default {
    created(){      
        var body = {};
        var userId;
        window.sessionStorage.setItem('checkIndex',window.location.href);
        var params = this.$route.query.params;
        if(params){
            body = JSON.parse(params);
            userId = body.userId;
        }else{
            body.code = this.$route.query.code;
            body.mobile = this.$route.query.mobile;
            userId = this.$route.query.userId;
            body.userId = userId;
        }
        body.userSource = 2;
        //设置通联用户
        allinpayUser.set(body)
        this.$toast.loading({duration: 0,forbidClick: true,message: '加载中···'});
        CheckCode(body).then(res=>{
            this.$toast.clear();
            var bind = ()=>{
                GetAllInPayUserInfo({userId:body.userId,userSource:body.userSource})
                .then(res=>{
                    // eslint-disable-next-line no-debugger
                    debugger
                    var source = this.$util.isMobile();
                    res.source = source?1:2;
                    allinpayUser.update(res)
                    //test start
                    // res.allinpayUser = 0;
                    //test end
                    if(res.allinPayUserStats===0){
                        this.$router.push('/bank/auth')
                        return
                    }else if(res.allinPayUserStats===7 && res.memberType===2){
                        allinpayUser.update({memberType:2})
                        this.$router.push({path:'/bank/companyAuth'})
                    }else if(res.allinPayUserStats ===1 && res.memberType === 2){
                        if(!res.allinPayPhome){
                            this.$router.push({path:'/bank/bindPhone',query:{scrapUserId:userId}})
                            return
                        }
                        this.$dialog.alert({
                            title: '提示',
                            message: '当前企业用户已认证成功！',
                            theme: 'round-button',
                        }).then(() => {
                            wx.closeWindow();
                        });
                    }else{
                        if(!res.allinPayPhome){
                            allinpayUser.update({memberType:3})
                            this.$router.push({path:'/bank/bindPhone',query:{scrapUserId:userId}})
                        }else if(!res.idCard){
                            this.$router.push({path:'/bank/realName',query:{scrapUserId:userId}})
                        }else if(!res.hasSign){
                            var href = window.location.origin
                            var t =href+"/bank/signStatus?&status=1"; 
                            var f =href+"/bank/signStatus?&status=0"; 
                            var signbody = {
                                "userId":userId,
                                "userSource": 2,
                                "jumpPageType": 1,
                                "backUrl":process.env.VUE_APP_PAY_NOTIFY_URL,
                                "jumpUrl": t,
                                "noContractUrl": f,
                                "source": source?1:2
                            };
                            this.$toast.loading({duration: 0,forbidClick: true,message: '获取电子合约'});
                            SignContract(signbody).then(res=>{
                                console.log('signContract url',res.data);
                                if(res.status){
                                    window.location.href=res.data.url;
                                }else{
                                    this.$toast.clear();
                                    this.$toast.fail("请求电子合约失败！");
                                }
                            }).catch(()=>{
                                this.$toast.clear();
                            });
                        }else if(!res.bindBankCard){
                            this.$router.push({path:'/bank/add',query:{scrapUserId:userId}})
                        }else{
                            this.$dialog.confirm({
                                title: '提示',
                                message: '检测到您已绑定银行卡，是否继续添加新的银行卡？',
                            }).then(() => {
                                this.$router.push({path:'/bank/add',query:{scrapUserId:userId}})
                            }).catch(() => {
                                wx.closeWindow();
                            });
                        }
                    }
                });
            }
            
            if(!res.status){
                this.$dialog.alert({
                    title: '提示',
                    message: '链接已过期，请重新获取！',
                }).then(() => {
                    wx.closeWindow();
                });
            }else{
                var bindFlag = Cookies.get('firstBind');
                if(!bindFlag){
                    this.$dialog.alert({
                        title: '提示',
                        message: '当前页面用于扳手报废用户绑定银行卡，车辆报废成功后，会通过此页面绑定的银行卡打入到您的账户！（当前链接10分钟有效，请及时操作）',
                        theme: 'round-button',
                    }).then(() => {
                        bind();
                        Cookies.set('firstBind',1);
                    });
                }else{
                    bind();
                }              
            }
        });
        
    }
}
</script>

<style>

</style>